@import './variables';

.font-weight-bold {
  font-weight: 700;
}

.font-weight-extra-bold {
  font-weight: 800;
}

.font-weight-black {
  font-weight: 900;
}

.font-info-light {
  color: $medium-grey;
}

.font-dark-blue-light-60 {
  color: rgba($dark-blue-light, .6);
}

.badge {
  font-size: 0.7rem;
  padding: 4px;
}

.lh-2 {
  line-height: 1.5rem;
}
.lh-3 {
  line-height: 2rem;
}

.text-capitalize-first::first-letter {
  text-transform: capitalize;
}

.secondary-label {
  color: $dark-grey
}
