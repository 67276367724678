@import './variables';
@import 'bootstrap/scss/mixins/breakpoints';

.card {
  &-header {
    &.card-header-large {
      min-height: 72px;
      @include media-breakpoint-down(sm) {
        min-height:1rem;
      }
    }
  }

  .card-header {
    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }
  }

  .card-body {
    @include media-breakpoint-down(sm) {
      padding-inline: 1rem;
      padding-block: 0.75rem;
    }
  }
}
