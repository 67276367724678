@import './variables';

.form-navigation {
  ul {
    margin: 0;
    padding: 0;

    &.sticky-top {
      z-index:1;
      top: 10rem;
    }

    li {
      list-style-type: none;
      text-align: center;

      a {
        color: $dark-grey;

        .icon-scroll-bar {
          background-image: url('../assets/images/icons/scroll-bar.svg');
        }

        .line {
          display: block;
          height: 30px;
          width: 1px;
          background-color: $grey;
        }

        .label {
          text-align: center;
          text-transform: uppercase;
          margin-top: 0.25rem
        }

        &.active {
          color: $blue;

          .icon-scroll-bar {
            background-image: url('../assets/images/icons/scroll-bar-active.svg');
          }

          .line {
            background-color: $blue;
          }
        }

        &.error {
          color: $red;

          .icon-scroll-bar {
            background-image: url('../assets/images/icons/scroll-bar-error.svg');
          }

          .line {
            background-color: $red;
          }
        }
      }
    }
  }
}
