@import './variables';
@import 'bootstrap/scss/mixins/breakpoints';

$padding-left-first-element: 1.5rem;

.table-flex {

  @include media-breakpoint-down(sm) {
    min-width: 992px;
  }

  tr {
    display: flex;
    flex-wrap: wrap;
  }

  td, th {
    display: flex;
    flex: 1;
    width: 0;
    align-items: center;
    overflow: hidden;
  }

  thead {
    position: relative;
    z-index: 1;
  }

  .collapse-row {
    padding-top: 0;
    padding-bottom: 0;
    flex-basis: 100%;
  }
}

.table {
  border-radius: 4px;
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0;

  td, th {
    &:first-child {
      padding-left: $padding-left-first-element;
    }
  }

  th {
    font-size: 10px;
    font-weight: 800;
    color: $grey;
    text-transform: uppercase;
    border-bottom: none !important;

    &.sortable {
      cursor: pointer;
      &:hover {
        color: $dark;
      }
      &.asc:after, &.desc:after {
        display: inline-block;
        position: absolute;
        margin-top: -1px;
        font-size: 14px;
      }
      &.asc:after {
        content: '\25BC';
      }
      &.desc:after {
        content: '\25B2';
        margin-top: -2px;
      }
    }
  }

  tbody {
    background: $white;

    tr {
      &:not(.nohover) {
        //@include hover() {
          background-color: rgba($blue, .05);
        //}
      }
    }

    td {
      vertical-align: middle;
      font-size: 13px;
      line-height: 16px;
    }
  }

  a {
    text-decoration: none;
  }

  &.border-top-0 {
    thead {
      th {
        border-top: none;
      }
    }
  }
}
