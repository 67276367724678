/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700;800;900&display=swap');
@import 'scss/variables';
@import 'scss/mixins';
@import 'bootstrap/scss/bootstrap';
@import 'scss/typography';
@import 'scss/public-footer';
@import 'scss/card';
@import 'scss/buttons';
@import 'scss/icons';
@import 'scss/dropdown';
@import 'scss/sidebar';
@import 'scss/layout';
@import 'scss/tables';
@import 'scss/borders';
@import 'scss/cursors';
@import 'scss/backgrounds';
@import 'scss/forms';
@import 'scss/virtual-scroller';
@import 'scss/multiselect-dropdown';
@import 'scss/map-markers-labels';
@import 'scss/state-circle';
@import 'scss/grids';
@import 'scss/empty-state';
@import 'scss/navigator';
@import 'scss/badges';

body {
  font-family: 'Mulish', sans-serif;
  background: $extra-light-grey;
}

.layout-collapse {
  .navbar-logo {
    .logo-full {
      display: none;
    }

    .logo-icon {
      display: inline-block !important;
    }
  }
}

.tooltip-word-wrap {
  white-space: pre-wrap !important;
}

.preserve-newlines {
  white-space: pre-wrap;
}

.modal-backdrop {
  z-index: 1040 !important;
}

[aria-live].visually-hidden {
  position: fixed;
}

.timepicker-backdrop-overlay, .timepicker-overlay, .timepicker {
  z-index: 1021 !important
}

.mapboxgl-popup-custom {
  .mapboxgl-popup-content {
    padding: 0;
  }
}

// 3-rd party
@import "../node_modules/@tomtom-international/web-sdk-plugin-searchbox/dist/SearchBox.css";
