@mixin circle-checkbox($color) {
  input[type=checkbox]:not(.switch-checkbox) {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid $color;
    background-image: none;

    &:checked {
      background-color: $color;
    }
  }
}

@mixin exact-width($width) {
  min-width: $width;
  width: $width;
  max-width: $width;
}
