@import 'bootstrap/scss/mixins/breakpoints';

$nav-size: 250px;
$nav-size-collapse: 88px;
$nav-size-hidden-nav: 0px;

@mixin layout($size) {
  .layout {
    &-nav {
      width: $size;
    }

    &-main {
      margin-left: $nav-size;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      width: calc(100vw - #{$size});
      max-width: calc(100% - #{$size});
    }
  }
}

.layout-collapse {
  @include layout($nav-size-collapse);

  @include media-breakpoint-down(lg) {
    @include layout(auto);
    &.hover-navigation {
      @include layout($nav-size-hidden-nav);
      .navbar-logo {
        display: none;
      }
    }
  }

  .nav-label {
    display: none;
  }

  .sidebar {
    .nav-link {
      padding: 4px 16px;

      &.active,
      &:hover {
        .nav-link-content {
          border-radius: 50%;
        }
      }

      &-collapse {
        .icon-collapse {
          background-image: url('../assets/images/icons/sidebar/expand.svg');
        }
      }
    }
  }

  .profile-name, .company-name {
    @include media-breakpoint-down(xs) {
      max-width: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.hover-navigation {
    .profile-name, .company-name {
      @include media-breakpoint-down(xs) {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@include layout($nav-size);

.flex-full-height {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .flex-1 {
    flex: 1;
  }
}
