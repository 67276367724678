@import './variables';

$icons: 'dashboard', 'transport', 'planning', 'stock', 'partners', 'location', 'assets', 'reports',
'company', 'tf-drive', 'users', 'subscription', 'document', 'payments', 'permissions', 'notifications', 'settings', 'sign';

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 81px 0 0;

  &-sticky {
    position: sticky;
    top: 0;
    height: calc(100vh - 81px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: #{$footer-height};
  }

  .portal-nav {

    .nav-link {
      color: $dark-grey;
      padding: 4px 0 4px 16px;
      white-space: nowrap;
      width: 100%;
      outline: none;
      cursor: pointer;

      &-collapse, &-down {
        border: none;
        background: transparent;

        .icon-collapse {
          background-image: url('../assets/images/icons/sidebar/collapse.svg');
        }
        .icon-down {
          background-image: url('../assets/images/icons/sidebar/down.svg');
        }
        .icon-up {
          background-image: url('../assets/images/icons/sidebar/up.svg');
        }
      }

      .nav-link-content {
        padding: 8px;
      }

      @each $icon in $icons {
        .icon-#{$icon} {
          background-image: url('../assets/images/icons/sidebar/#{$icon}.svg');

          &:after {
            content: '';
            background-image: url('../assets/images/icons/sidebar/#{$icon}-active.svg');
            height: 0;
            width: 0;
            visibility: hidden;
          }
        }
      }

      &.active,
      &:hover {
        .nav-link-content {
          background: $dark-blue-light;
          color: $white;
          border-radius: 28px 0 0 28px;
        }

        @each $icon in $icons {
          .icon-#{$icon} {
            background-image: url('../assets/images/icons/sidebar/#{$icon}-active.svg');
          }
        }
      }
    }

    ul {
      padding-left: 0;
      > li {
        > .nav-link {
          color: rgba($dark-grey, .75);
          font-size: .85rem;
        }
      }
    }

    .border-top-dark-grey {
      border-top: 0.5px solid $dark-grey;
    }
  }
}
