@import './variables';

.bg {
  &-extra-light-grey{
    background: $extra-light-grey;
  }

  &-extra-lighter-grey{
    background: $extra-lighter-grey;
  }

  &-dark-blue-light {
    background: $dark-blue-light
  }

  &-primary-light {
    background: rgba($blue, .1);
  }

  &-grey {
    background: $grey;
  }

  &-light-grey {
    background: $light-grey;
  }

  &-login {
    background-image: url('/assets/images/bg-login.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
