@import "./variables";

$icons-with-hover: 'share', 'delete', 'duplicate', 'hamburger', 'edit', 'chevron-right', 'pdf', 'give-permissions';
$icons-without-hover: 'add-new-active', 'cash-on-delivery', 'chevron-down', 'chevron-up', 'chevron-left', 'chevron-right-active', 'delete-active',
'edit-active', 'pdf-active', 'status-cancelled', 'status-delivered', 'status-transit', 'status-issued', 'status-draft', 'status-inspection',
'more', 'info', 'info-primary', 'info-light', 'info-warning', 'pickup', 'carrier', 'goods', 'observation', 'observation-indicator', 'event-log', 'delivery','search', 'active', 'ref', 'ref-active',
'dangerous-goods', 'dangerous-goods-active', 'clear', 'attachment', 'time', 'container', 'vehicle', 'rtis', 'references', 'download',
'instructions', 'logout', 'search-close', 'true', 'false', 'calendar', 'card-expand', 'card-collapse', 'locate',
'yes', 'no', 'notification', 'notification-active', 'email', 'close', 'observations-alert', 'vibrate', 'transport-multiple', 'transport-simple',
'mission-collection-circle', 'mission-collection-circle-no-bg', 'mission-delivery-circle', 'mission-delivery-circle-no-bg', 'vehicle-eta_unknown-circle', 'vehicle-on_time-circle', 'vehicle-at_risk-circle', 'vehicle-late-circle', 'vehicle-point-circle', 'mission-carrier-to-carrier-circle', 'tf-drive-planning-tool', 'map-anchor', 'share-active';

.icon {
  background-repeat: no-repeat;
  background-position: center center;
  width: 40px;
  height: 40px;
  display: inline-block;

  &.icon-narrow {
    width: 28px;
    height: 28px;
    background-size: 36px;
  }

  &.icon-small {
    width: 22px;
    height: 22px;
  }

  &.icon-tiny {
    width: 16px;
    height: 16px;
  }

  @each $icon in $icons-with-hover {
    &.icon-#{$icon} {
      background-image: url('../assets/images/icons/#{$icon}.svg');

      &:after {
        content: '';
        background-image: url('../assets/images/icons/#{$icon}-active.svg');
        height: 0;
        width: 0;
        visibility: hidden;
      }
    }
  }

  @each $icon in $icons-without-hover {
    &.icon-#{$icon} {
      background-image: url('../assets/images/icons/#{$icon}.svg');
    }
  }

  .hover-icon-wrapper:hover & {
    @each $icon in $icons-with-hover {
      &.icon-#{$icon} {
        background-image: url('../assets/images/icons/#{$icon}-active.svg');
      }
    }
  }

  &-with-badge {
    position: relative;

    .rounded-pill {
      position: absolute;
      right: 6px;
      top: 6px;
      padding-inline: 0.35em;
    }
  }
}

.tf-drive-icon::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('../assets/images/icons/tf-drive-planning-tool.svg');
  background-size: cover;
  vertical-align: text-bottom;
}
