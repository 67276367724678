$white: #ffffff;
$dark-blue: #000f2b;
$dark-blue-light: #0f1e3c;
$grey: #b9c3da;
$grey-disabled: #6c757d;
$medium-grey: #acb5cb;
$light-grey: #e3e6ee;
$dark-grey: #6c78a0;
$blue: #0086ff;
$extra-light-grey: #f2f4f8;
$extra-lighter-grey: #f9fafb;
$yellow: #fead02;
$orange: #ff6e32;
$light-orange: #ff7f32;
$red: $orange;
$light-red: #ff4c32;
$dark-red: #ff3b32;
$green: #39aa96;
$light-green: #39aaa9;
$oval-style: #c8cedd;
$whatsapp-color: #25d366;
$telegram-color: #24a1de;
$viber-color: #7360f2;

// bootstrap override
$body-color: $dark-blue;
$dark: $dark-blue;

$grid-gutter-width: 48px;

$h1-font-size: 26px;
$h2-font-size: 18px;
$h3-font-size: 14px;
$h4-font-size: 12px;
$h5-font-size: 10px;

$headings-margin-bottom: 0;

$card-cap-bg: rgba($oval-style, 0.1);
$card-border-color: rgba($dark-grey, 0.15);
$card-cap-color: $dark-blue;
$card-spacer-x: 32px;
$card-color: $dark-blue;
$input-border-radius: 0;
$dropdown-border-radius: 0;
$dropdown-min-width: 100%;
$dropdown-padding-y: 0;
$dropdown-item-padding-x: 16px;

$dropdown-link-active-color: $dark-blue;
$dropdown-link-hover-color: $blue;
$dropdown-link-hover-bg: rgba($oval-style, 0.5);
$dropdown-font-size: 12px;
$dropdown-link-active-bg: $white;

$table-color: $dark-blue;
$table-bg: $white;
$table-accent-bg: rgba($oval-style, 0.1);
$table-hover-bg: transparent;
$table-cell-padding: 12px;

$badge-color: black;
$badge-border-radius: 0.5em;

$form-feedback-icon-invalid: "";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);

$footer-height: 78px;

$tooltip-bg: $dark-blue-light;
$tooltip-max-width: 400px;
