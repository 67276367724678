@import './variables';
@import 'bootstrap/scss/mixins/breakpoints';

.label-value-grid {
  display: grid;
  grid-template-columns: auto minmax(75%, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  align-items: start;

  > * {
    word-break: break-word;
  }

  label {
    color: $dark-grey
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: auto minmax(50%,1fr);
    grid-row-gap: 0.25rem;
  }
}

.col-span-2 {
  grid-column: span 2
}
.col-span-3 {
  grid-column: span 3
}
.col-span-4 {
  grid-column: span 4
}
.col-span-6 {
  grid-column: span 6;
}
.row-span-2 {
  grid-row: span 2;
}
