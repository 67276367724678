@import './variables';

.public-footer-nav {
  background: $light-grey;

  @extend .h4;
  @extend .text-dark;

  .item {
    @extend .font-weight-extra-bold;
    @extend .text-dark;
  }
}
