@import './variables';

.btn-outline-primary {
  --bs-btn-active-color: white;
  --bs-btn-hover-color: white;
}

.button {
  &-primary {
    --bs-btn-color: white;
    --bs-btn-hover-color: white;
    --bs-btn-active-color: white;
    --bs-btn-disabled-color: white;
    @extend .btn;
    @extend .btn-primary;
    @extend .rounded-pill;
    @extend .font-weight-black;
    @extend .text-nowrap;
    height: 40px;
    font-size: 11px;
    padding-left: 24px;
    padding-right: 24px;
    text-transform: uppercase;
    line-height: 26px;
  }

  &-warning {
    @extend .button-primary;
    background-color: $orange;
    border-color: $orange;

    &:hover, &:focus-visible {
      background-color: $light-orange !important;
      border-color: $light-orange !important;
    }

    &:active {
      background-color: $light-red !important;
      border-color: $light-red !important;
    }
  }

  &-secondary {
    --bs-btn-active-color: white;
    --bs-btn-hover-color: white;
    @extend .btn;
    @extend .btn-outline-primary;
    @extend .rounded-pill;
    @extend .font-weight-bold;
    @extend .text-nowrap;
    height: 32px;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 20px;
  }

  &-tertiary {
    @extend .btn;
    @extend .btn-link;
    @extend .font-weight-extra-bold;
    @extend .text-nowrap;
    text-decoration: underline;
    font-size: 12px;
    padding: 0;
    text-transform: uppercase;
    line-height: 1em;
    height: 16px;
    text-underline-offset: 0.1rem;
  }

  &-dropdown {
    @extend .btn;
    @extend .font-weight-bold;
    @extend .rounded-0;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-between;
    @extend .text-nowrap;
    font-size: 12px;
    line-height: 18px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 24px;
    }

    & > .icon {
      @include media-breakpoint-down(md) {
        height: 30px;
        width: 30px;
      }
    }
  }

  /** REmove this button-icon class with button.icon - Check through the code to remove it **/
  &-icon {
    @extend .btn;
    @extend .border-0;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 0;

    span {
      display: block;
    }

    > fa-icon {
      font-size: 22px;
    }

    &-small {
      height: 20px;
      width: 20px;
    }
  }
}

.btn-link {
  @extend .btn-link;
  cursor: pointer;

  &-colored {
    color: $blue;
  }
}
.btn-inactive {
  cursor: default !important;
}
button.icon {
  @extend .btn;
  @extend .border-0;
  border-radius: 50%;
}

button.button-switch {
  @extend .btn;
  @extend .btn-outline-primary;
  fa-icon {
    color:grey;
  }
  &:hover, &.selected {
    @extend .btn-primary;
    fa-icon {
      color:white;
    }
  }
}

.button-icon-action {
  height: 40px;
  width: 40px;
  background: transparent;
  border:2px solid transparent;
  border-radius: 50%;
  padding: 4px;

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: rgba($blue,0.5);
    &.hover-warning {
      border-color: rgba($orange,0.5);
    }
  }

  .icon {
    width: 28px !important;
    height: 28px !important;
  }
  fa-icon>svg {
    color: $blue;
    width: 28px !important;
    height: 28px !important;
    padding: 3px;
  }
}
