@import './variables';

.dropdown {
  body > & {
    z-index: $zindex-dropdown!important;
  }

  &-toggle {
    &.button-dropdown, &.button-icon {
      &:after,
      &:before {
        display: none !important;
      }
    }
  }

  &-item {
    border-bottom: 1px solid rgba($oval-style, .5);
    display: flex;
    align-items: center;

    .prefix {
      margin: -4px 8px -4px -16px;
    }

    .postfix {
      margin: -4px -16px -4px 8px;
    }

    &:last-child {
      border-bottom: none;
    }

    &:active,
    &.active {
      @extend .font-weight-extra-bold;
    }

    &.disabled,
    &:disabled {
      filter: grayscale(100%);
      span.icon {
        opacity: .3;
      }
    }
  }
}

ngb-datepicker.dropdown-menu {
  min-width: auto;
}
