@import './variables';

.state-circle {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: $light-grey;

  &.active {
    background-color: $green;
  }
}
