@mixin number-circle {
  width: 24px;
  height: 24px;
  border: solid 2px black;
  background-color: var(--white);
  border-radius: 50%;
  padding: 2px 0;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  text-align: center;
}

.number-circle {
  @include number-circle;
}

.agm-map-container-inner div[style*="color: black; font-size: 14px; font-family: Roboto, Arial, sans-serif;"] {
  @include number-circle;
  margin-bottom: 70px;
}

.agm-map-container-inner div[style*="color: orange; font-size: 14px; font-family: Roboto, Arial, sans-serif;"] {
  background-image: url('../assets/images/icons/observations-alert-white.svg');
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin-bottom: 70px;
  margin-left: 30px;
  border: none;
}

.truck-badge {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  background: white;
  width: fit-content;
  max-width: 200px;
  height: 34px;
  border-radius: 17px;
  padding-right: 10px;

  > i {
    margin-top: 2px;
    margin-left: -3px;
    flex-shrink: 0;
  }

  > .content {
    font-size: 12px;
    line-height: 15px;
    display: flex;
    flex-direction: column;
    max-width: 150px;
    max-height: 50px;
  }
}

.mission-marker-description {
  font-weight: bold;
  max-width: 150px;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
