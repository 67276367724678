@import "./variables";

@mixin badge($color: color, $background: background) {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 600;
  border-radius: 1.5rem;
  background: $background;
  color: $color;
  border: 1px solid $color;

  fa-icon {
    font-size: 1.25rem;
  }
}

.badge-primary-outline {
  @include badge($blue, $white);

  fa-icon {
    &:hover {
      cursor: pointer;
    }

    &.hover-danger:hover {
      color: $red
    }
  }

  &-green {
    @include badge($green, $white);
  }
}

.badge-disabled {
  @include badge($grey, $white);
}

.badge-error {
  @include badge($dark-red, $white);
}
