@import './variables';
@import 'bootstrap/scss/mixins/breakpoints';

.required {
  label {
    &:after {
      content: " *";
      position: absolute;
      margin-left: 4px;
    }
  }
}

.input {
  &-with-icon {
    &-prepend {
      position: relative;

      .prepend {
        position: absolute;
        left: 8px;
      }

      input {
        padding-left: 48px;
      }
    }

    &-append {
      position: relative;

      .append {
        position: absolute;
        right: 0;
        top: 0;
      }

      input {
        padding-right: 40px;
      }
    }
  }

  &-error {
    flex-basis: 100%;
  }
}

.col-form-label {
  padding-right: 0;
}

.form-control.drop.form-group.required {
  .col-form-label {
    &:after {
      content: " *";
      position: absolute;
      margin-left: 4px;
    }
  }
}

input[type="checkbox"]:not(.switch-checkbox) {
  --background-image: url("../assets/images/icons/checkbox-inactive.svg");
  --background-image-checked: url("../assets/images/icons/checkbox-active.svg");
  --background-image-disabled: url("../assets/images/icons/checkbox-disabled.svg");
  --background-image-disabled-checked: url("../assets/images/icons/checkbox-active-disabled.svg");
  --width: 18px;

  &.checkbox-large {
    --background-image: url("../assets/images/icons/checkbox-inactive-large.svg");
    --background-image-checked: url("../assets/images/icons/checkbox-active-large.svg");
    --background-image-disabled: url("../assets/images/icons/checkbox-disabled-large.svg");
    --background-image-disabled-checked: url("../assets/images/icons/checkbox-active-disabled-large.svg");
    --width: 36px;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  height: var(--width);
  width: var(--width);
  background-image: var(--background-image);
  background-position-x: center;
  background-position-y: center;
  background-size: unset;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-top: 0;
  margin-right: 0.5rem;
  flex: 0 0 auto;

  &:checked {
    background-image: var(--background-image-checked);

    & + label {
      font-weight: bold;
    }
  }

  &:disabled {
    background-color: transparent;
    border-color: transparent;
    background-image: var(--background-image-disabled);

    &:checked {
      background-image: var(--background-image-disabled-checked);
    }
  }
}

.form-check {
  &-late {
    @include circle-checkbox($orange);
  }

  &-at_risk {
    @include circle-checkbox($yellow);
  }

  &-on_time {
    @include circle-checkbox($green);
  }

  &-eta_unknown {
    @include circle-checkbox($black);
  }
}

.form-control.dropdown-select {
  background-image: url("../assets/images/icons/chevron-down.svg");
  background-size: 15px 9px;
  background-position: right 12px center;
  background-repeat: no-repeat;
  padding-right: 40px;
}

input[type="search"] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &:required {
    box-shadow: none;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($blue, 0.25);
    }
  }

  &:invalid + .icon-search-close {
    display: none;
  }
}

input[type="time"] {
  &::-webkit-calendar-picker-indicator,
  &::-webkit-calendar-picker-indicator:focus {
    display: none;
  }
}

.down-select {
  background-image: url("../assets/images/icons/chevron-down.svg");
  background-size: 15px 9px;
  background-position: right 12px center;
  background-repeat: no-repeat;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: $medium-grey !important;
  font-size: $h4-font-size;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: $medium-grey !important;
  opacity: 1;
  font-size: $h4-font-size;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $medium-grey !important;
  font-size: $h4-font-size;
}

::placeholder { /* Most modern browsers support this now. */
  color: $medium-grey !important;
  font-size: $h4-font-size;
}

.message-text-area-xl {
  height: 8em !important;
}

form .row {
  padding-block: 0.5rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  padding-right: 0.75rem;
}

.col-form-label, .form-control-plaintext {
  @include media-breakpoint-down(sm) {
    padding-block: 0.2rem;
  }
}

.input-group {
  &.form-number-label {
    input {
      flex-basis: 35%
    }

    select {
      flex-basis: 65%
    }
  }
  &.form-value-unit {
    input {
      flex-basis: 65%
    }

    select {
      flex-basis: 35%
    }
  }
}
