$base-color: $blue;
$btn-background: $white;
$btn-border: $gray-400;
$btn-text-color: $dark-blue;
$btn-arrow: $blue;

$token-background: $base-color;
$token-text-color: $white;
$token-remove-color: $white;

$label-color: $dark-blue;

angular2-multiselect {
  &.disabled .c-btn {
    background: $gray-200 !important;
    pointer-events: none;
  }
}

.c-btn {
  background: $btn-background;
  border: 1px solid $btn-border!important;
  border-radius: 0!important;
  color: $btn-text-color!important;

  &:focus {
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-btn-focus-box-shadow;
  }
}

.selected-list {
  .c-list {
    .c-token {
      background: $token-background;

      .c-label {
        color: $token-text-color;
      }

      .c-remove {
        svg {
          fill: $token-remove-color;
        }
      }

    }
  }

  .c-angle-down, .c-angle-up {
    height: auto!important;
    svg {
      fill: $btn-arrow!important;
    }
  }
}

.dropdown-list {
  z-index: $zindex-dropdown!important;
}

.arrow-up, .arrow-down {
  border-bottom: 15px solid #fff;
}

.arrow-2 {
  border-bottom: 15px solid #ccc;
}

.list-area {
  border: 1px solid $dropdown-border-color;
  background: $white;
  box-shadow: $dropdown-box-shadow;
  border-radius: $dropdown-border-radius!important;
}

.pure-checkbox {
  input[type="checkbox"]:focus + label:before,
  input[type="checkbox"]:hover + label:before {
    border-color: $base-color;
    background-color: #f2f2f2;
  }

  &:hover {
    input[type="checkbox"] + label {
      color: $dropdown-link-hover-color!important;
    }
  }

  input[type="checkbox"] + label {
    color: $label-color;
    font-size: 12px;
    padding-left: 2.3em!important;
  }

  input[type="checkbox"]:checked + label {
    font-weight: 700!important;
  }

  input[type="checkbox"] + label:before {
    border: 2px solid #dde1ea;
    width: 18px!important;
    height: 18px!important;
    box-sizing: border-box!important;
  }

  input[type="checkbox"] + label:after {
    margin-top: -4px!important;
    left: 4px!important;
    color: $base-color;
  }

  input[type="checkbox"]:checked + label:before {
    border-color: #89c2ff;
  }
}
